import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  genealogyData: {},
  loadingStatus: false
};

const slice = createSlice({
  name: 'genealogyData',
  initialState,
  reducers: {
    setLoadingStatusToTrue(state) {
        state.loadingStatus = true;
    },
    getGenealogyData(state, action) {
      const genealogyData = {...action.payload};
      state.genealogyData = genealogyData;
      state.loadingStatus = false;
    },
  }
});

export const reducer = slice.reducer;

export const getGenealogyData = (params) => async (dispatch) => {
  const url = 'https://genealogy-api-u5xg6yr6kq-ue.a.run.app/genealogy-tool/v1/genealogy-tables/' + params.keyType + '/' + params.keyValues + '?cops_test_lot_bool=' + params.copsTestLotBool + '&reel_purity_fix_bool=' + params.reelPurityFixBool + '&date_code_bool=' + params.dateCodeBool;
  var bearer = 'Bearer ' + params.token;
  const response = await fetch(url, {
          method: 'GET',
          withCredentials: true,
          credentials: 'include',
          headers: {
              'Authorization': bearer,
              'Content-Type': 'application/json'
          }
      });
  const data = await response.json();
  dispatch(slice.actions.getGenealogyData(data));
};

export const setLoadingStatusToTrue = () => (dispatch) => {
  dispatch(slice.actions.setLoadingStatusToTrue());
};

export default slice;
