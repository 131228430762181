/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Hidden,
  InputLabel,
  Link,
  List,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Copy as CopyIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { useDispatch, useSelector } from 'src/store';
import { getGenealogyData, setLoadingStatusToTrue } from 'src/slices/genealogyData';
import { toggleCheckbox, showOutputOptions } from 'src/slices/outputOptions';
import { setFirstTrace } from 'src/slices/user';
import { apiRequest } from 'src/config';
import { useMsal } from 'src/contexts/msal-context';


const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 300,
    top: 80,
    height: 'calc(100% - 80px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  formControl: {
    margin: 'dense',
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const loadingStatus = useSelector((state) => state.genealogyData.loadingStatus);
  const outputOptions = useSelector((state) => state.outputOptions);
  const keyTypesAndPlaceholders = [
      {
         keyType: 'FAB WAFER',
         placeholders: '1836233-02\n1836233-03\n1836233-04'
      },
      {
         keyType: 'WAFER BATCH',
         placeholders: '183623302\n183623303\n183623304'
      },
      {
         keyType: 'TRACE CODE',
         placeholders: 'U31004CE\nU31004CF\nU31003T8'
      },
      {
         keyType: 'TEST LOT',
         placeholders: 'F06-266761\nF09-99EDH5\nU03-016341'
      },
      {
         keyType: 'COPS TEST LOT',
         placeholders: '201123401A1T1\nU0101A2X\nZ01004K0'
      },
      {
         keyType: 'REEL',
         placeholders: 'U32-056619\nU32-056620\nU32-056621'
      },
      {
         keyType: 'MIXED',
         placeholders: 'U31004CE\nF06-266761\nU32-056621'
      },
  ];
  const [keyType, setKeyType] = useState('FAB WAFER');
  const [keyValues, setKeyValues] = useState('1836233-02,1836233-03,1836233-04');
  const [keyValuesPlaceholder, setKeyValuesPlaceholder] = useState('1836233-02\n1836233-03\n1836233-04');

  const { getToken } = useMsal();
  //const apiToken = useSelector((state) => state.user.apiToken);

  const params = {
      keyType: keyType,
      keyValues: keyValues,
      copsTestLotBool: outputOptions.copsTestLotBool,
      reelPurityFixBool: outputOptions.reelPurityFixBool,
      dateCodeBool: outputOptions.dateCodeBool,
      token: localStorage.apiToken
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const keyTypeChangedHandler = (event) => {
      setKeyType(event.target.value);
      let keyValueObject = keyTypesAndPlaceholders.find(o => o.keyType === event.target.value);
      setKeyValuesPlaceholder(keyValueObject.placeholders);
  }

  const keyValuesChangedHandler = (event) => {
      const keyValuesArray = event.target.value.split('\n');
      const keyValuesString = keyValuesArray.join(',');
      setKeyValues(keyValuesString);
  }

  const traceInputsHandler = async () => {
      dispatch(setLoadingStatusToTrue());
      dispatch(setFirstTrace(false));
      apiRequest.account = user.account;
      await getToken(apiRequest, "loginRedirect");
      params.token = localStorage.apiToken;
      dispatch(getGenealogyData(params));
  }

  const handleCheckboxChange = (event) => {
      // setState({ ...state, [event.target.name]: event.target.checked });
      const toggleParams = {
          name: event.target.name,
          checked: event.target.checked
      };
      dispatch(toggleCheckbox(toggleParams));
  }

  const outputOptionsSection = () => {
      if (outputOptions.currentTab === 'summary' && outputOptions.showOutputOptions) {
          return (
              <div>
               <Typography variant='h4'>
                   Output Options
               </Typography>
               <Box mb={2}/>
               <FormHelperText>Choose columns to display</FormHelperText>
                  <Box mb={-1}>
                     <FormControlLabel
                         control={<Checkbox
                             checked={outputOptions.componentCheckbox}
                             onChange={handleCheckboxChange}
                             name="componentCheckbox"
                             size="small" />}
                             label={<Typography variant='body2'>COMPONENT</Typography>}
                     />
                  </Box>
                  <Box mb={-1}>
                     <FormControlLabel
                         control={<Checkbox
                             checked={outputOptions.traceCodeCheckbox}
                             onChange={handleCheckboxChange}
                             name="traceCodeCheckbox"
                             size="small" />}
                             label={<Typography variant='body2'>TRACE CODE</Typography>}
                     />
                  </Box>
                  <Box mb={-1}>
                     <FormControlLabel
                         control={<Checkbox
                             checked={outputOptions.testLotCheckbox}
                             onChange={handleCheckboxChange}
                             name="testLotCheckbox"
                             size="small" />}
                             label={<Typography variant='body2'>TEST LOT</Typography>}
                     />
                  </Box>
                  <Box mb={-1}>
                     <FormControlLabel
                         control={<Checkbox
                             checked={outputOptions.copsTestLotCheckbox}
                             onChange={handleCheckboxChange}
                             name="copsTestLotCheckbox"
                             size="small" />}
                             label={<Typography variant='body2'>COPS TEST LOT</Typography>}
                     />
                  </Box>
                  <Box mb={-1}>
                     <FormControlLabel
                         control={<Checkbox
                             checked={outputOptions.reelCheckbox}
                             onChange={handleCheckboxChange}
                             name="reelCheckbox"
                             size="small" />}
                             label={<Typography variant='body2'>REEL</Typography>}
                     />
                  </Box>
                  <Box mb={-1}>
                     <FormControlLabel
                         control={<Checkbox
                             checked={outputOptions.overviewCheckbox}
                             onChange={handleCheckboxChange}
                             name="overviewCheckbox"
                             size="small" />}
                             label={<Typography variant='body2'>GENEALOGY OVERVIEW</Typography>}
                     />
                  </Box>
                  <Box mb={2}>
                     <FormControlLabel
                         control={<Checkbox
                             checked={outputOptions.partNumbersCheckbox}
                             onChange={handleCheckboxChange}
                             name="partNumbersCheckbox"
                             size="small" />}
                             label={<Typography variant='body2'>PART NUMBERS</Typography>}
                     />
                  </Box>
                  <Box mb={0}>
                     <FormControlLabel
                       control={<Switch
                            checked={outputOptions.reverseColumnsCheckbox}
                            onChange={handleCheckboxChange}
                            name="reverseColumnsCheckbox"
                            size="small"/>}
                            label={<Typography variant='body2'>REVERSE COLUMNS</Typography>}
                     />
                  </Box>
              </div>
       );
      }
  }


  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box ml={3} mt={2.5} mr={3} mb={4}>
            <Typography variant='h4'>
                Input Options
            </Typography>
            <Box mb={2}/>
            <FormHelperText>Choose extra columns</FormHelperText>
              <Box mb={-1}>
                <FormControlLabel
                  control={<Checkbox
                      checked={outputOptions.dateCodeBool}
                      onChange={handleCheckboxChange}
                      size="small"
                      name="dateCodeBool" />}
                  label={<Typography variant='body2'>DATE CODE</Typography>}
                />
              </Box>
              <Box mb={-1}>
                <FormControlLabel
                  control={<Checkbox
                      checked={outputOptions.copsTestLotBool}
                      onChange={handleCheckboxChange}
                      size="small"
                      name="copsTestLotBool" />}
                  label={<Typography variant='body2'>COPS TEST LOT</Typography>}
                />
              </Box>
              <Box mb={0} ml={3}>
                <FormControlLabel
                  control={<Checkbox
                      checked={outputOptions.reelPurityFixBool}
                      onChange={handleCheckboxChange}
                      name="reelPurityFixBool"
                      size="small"
                      disabled={!outputOptions.copsTestLotBool}/>}
                  label={<Typography variant='body2'>REEL PURITY CORRECTION</Typography>}
                />
              </Box>
            <Box mb={3} />
            <TextField
              fullWidth
              id="keyTypeInput"
              select
              label="Key Type"
              value={keyType}
              onChange={keyTypeChangedHandler}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              {keyTypesAndPlaceholders.map((option) => (
                <option key={option.keyType} value={option.keyType}>
                  {option.keyType}
                </option>
              ))}
            </TextField>
            <Box mb={2}/>
            <TextField
              fullWidth
              id="keyValuesInput"
              label="Key Values"
              placeholder={keyValuesPlaceholder}
              multiline
              rows={7}
              variant="outlined"
              onChange={keyValuesChangedHandler}
            />
            <Box mb={2}/>
            <Button
            variant='outlined'
            fullWidth
            disabled={loadingStatus}
            onClick={traceInputsHandler}>
            Trace Inputs
            </Button>
        </Box>
        <Divider/>
        <Box ml={3} mt={2.5} mr={3}>
        {outputOptionsSection()}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
