import React from 'react';

const SignalSymbol = (props) => {
  return (
    <img
      height="43px"
      alt="SignalSymbol"
      src="/static/qorvo-wifi-02.png"
      {...props}
    />
  );
}

export default SignalSymbol;
