import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import axios from 'src/utils/axios';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Header from './Header';
import Results from './Results';
import { useDispatch, useSelector } from 'src/store';
import { getGenealogyData } from 'src/slices/genealogyData';
import { uniq } from 'ramda';
import { useMsal } from 'src/contexts/msal-context';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const { token } = useMsal();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { genealogyData } = useSelector((state) => state.genealogyData);
  const loadingStatus = useSelector((state) => state.genealogyData.loadingStatus);
  const outputOptions = useSelector((state) => state.outputOptions);
  const user = useSelector((state) => state.user);

  const destruct = (obj, keys) => keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {});

  const getColumnsToInclude = (outputOptions, columnNames) => {
      let columnsToRemove = [];
      if (!outputOptions.componentCheckbox) {
          columnsToRemove.push('componentBatch','componentPartNumber','componentType');
      }
      if (!outputOptions.traceCodeCheckbox) {
          columnsToRemove.push('traceCode','traceCodePartNumber');
      }
      if (!outputOptions.testLotCheckbox) {
          columnsToRemove.push('testLot','testLotPartNumber');
      }
      if (!outputOptions.copsTestLotCheckbox) {
          columnsToRemove.push('copsTestLot','copsTestLotPartNumber');
      }
      if (!outputOptions.reelCheckbox) {
          columnsToRemove.push('reel','reelPartNumber');
      }
      if (!outputOptions.overviewCheckbox) {
          columnsToRemove.push('genealogyOverviewMax','numberOfLevelsMax');
      }
      if (!outputOptions.partNumbersCheckbox) {
          columnsToRemove.push('componentPartNumber','traceCodePartNumber','testLotPartNumber',
          'copsTestLotPartNumber','reelPartNumber');
      }

      const columnsToInclude = columnNames.filter(x => !columnsToRemove.includes(x));

      if (outputOptions.reverseColumnsCheckbox) {
          return columnsToInclude.reverse();
      }
      else {
          return columnsToInclude;
      };
  };

  let tables = {}
  if (Object.keys(genealogyData).length > 1) {
      let updatedTables = {...genealogyData};
      const columnNames = Object.keys(updatedTables.summary[0]);
      const columnsToInclude = getColumnsToInclude(outputOptions, columnNames);
      updatedTables.summary = genealogyData.summary.map((rowObj) => destruct(rowObj, columnsToInclude));
      updatedTables.summary = uniq(updatedTables.summary);
      tables = updatedTables;
  };

  const content = () => {
      if (loadingStatus) {
          return (
                <CircularProgress />
          )
      }
      else if (Object.keys(tables).length <= 1) {
         if (Object.keys(tables).includes('message')) {
           return (
               <Typography color="textPrimary">
                 Your authentication token appears to have expired. Try refreshing this web page to log in again.
               </Typography>
         )}
         else if (user.firstTrace) {
             return (
             <Typography color="textPrimary">
               Enter the key type and key values that you would like to get genealogy data for, then hit the 'Trace Inputs' button.
             </Typography>
             )}
         else {
           return (
               <Typography color="textPrimary">
                 Tracing attempted for all inputs, but no genealogy data was found.
               </Typography>
           )}
      }
      else {
          return (
              <Results tables={tables} />
          )
      }
  }

  //const [tables, setTables] = useState([]);

  // useEffect(() => {
  //   dispatch(getGenealogyData());
  // }, [dispatch]);

  return (
    <Page
      className={classes.root}
      title="IDP Genealogy"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          {content}
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
