import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  Upload as UploadIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const Header = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div>
        <Grid
          className={clsx(classes.root, className)}
          container
          justify="space-between"
          spacing={3}
          {...rest}
        >
          <Grid item>
            <Typography
              variant="h4"
              color="textPrimary"
            >
              Results
            </Typography>
          </Grid>
        </Grid>
        <Box mb={2}/>
       </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
