import React from 'react';

const Logo = (props) => {
  return (
    <img
      height="43px"
      alt="Logo"
      src="/static/qorvo-logo-blue-r-rgb.png"
      {...props}
    />
  );
}

export default Logo;
