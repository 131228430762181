import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  componentCheckbox: true,
  traceCodeCheckbox: true,
  testLotCheckbox: true,
  copsTestLotCheckbox: true,
  reelCheckbox: true,
  overviewCheckbox: true,
  partNumbersCheckbox: true,
  reverseColumnsCheckbox: false,
  copsTestLotBool: true,
  reelPurityFixBool: true,
  dateCodeBool: false,
  currentTab: 'summary',
  showOutputOptions: false
};

const slice = createSlice({
  name: 'outputOptions',
  initialState,
  reducers: {
    showOutputOptions(state, action) {
        const showOutputOptions = action.payload;
        state.showOutputOptions = showOutputOptions;
    },
    setCurrentTab(state, action) {
        const currentTab = action.payload;
        state.currentTab = currentTab;
    },
    toggleCheckbox(state, action) {
      const toggleParams = {...action.payload};
      state[toggleParams.name] = toggleParams.checked;
    }
  }
});

export const reducer = slice.reducer;

export const toggleCheckbox = (toggleParams) => (dispatch) => {
  dispatch(slice.actions.toggleCheckbox(toggleParams));
};

export const setCurrentTab = (currentTab) => (dispatch) => {
  dispatch(slice.actions.setCurrentTab(currentTab));
};

export const showOutputOptions = (showOutputOptions) => (dispatch) => {
  dispatch(slice.actions.showOutputOptions(showOutputOptions));
};

export default slice;
