import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: ["DIN Next Rounded LT W04 Medium", 'Arial', 'sans-serif']
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const Title = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
        <Typography
          variant="h3"
          align="left"
          color="textPrimary"
          className={classes.typography}
        >
          IDP
          <br />
          <strong>Genealogy</strong>
        </Typography>
    </div>
  );
};

Title.propTypes = {
  className: PropTypes.string
};

export default Title;
