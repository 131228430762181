import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Link,
  Toolbar,
  Typography,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import SignalSymbol from 'src/components/SignalSymbol';
import { THEMES } from 'src/constants';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import Title from './Title';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: theme.zIndex.drawer + 100
    },
  toolbar: {
    height: 80
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Title />
        <Box ml={2} />
        <SignalSymbol />
        <Box
          ml={2}
          flexGrow={16}
        />
        <Box
          ml={2}
          flexGrow={1}
        >
            <Typography
              variant='body2'
              color="textSecondary">
                Add genealogy data to your own application
            </Typography>
            <Link
              className={classes.link}
              color="textPrimary"
              component={RouterLink}
              to="/docs"
              underline="none"
              variant="body1"
            >
              See the documentation to find out how
            </Link>
        </Box>
        <Box ml={2}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
