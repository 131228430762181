import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as genealogyDataReducer } from 'src/slices/genealogyData';
import { reducer as outputOptionsReducer } from 'src/slices/outputOptions';
import { reducer as userReducer } from 'src/slices/user';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  genealogyData: genealogyDataReducer,
  outputOptions: outputOptionsReducer,
  user: userReducer
});

export default rootReducer;
