import React, { useState, useEffect, useContext } from "react";
import * as msal from "@azure/msal-browser";
import { useDispatch } from 'src/store';
import { setLoginToken, setApiToken, setAccount } from 'src/slices/user';

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;

export const MsalContext = React.createContext();
export const useMsal = () => useContext(MsalContext);
export const MsalProvider = ({
    children,
    config
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [token, setToken] = useState();
    const [publicClient, setPublicClient] = useState();
    const [loading, setLoading] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {

        const pc = new msal.PublicClientApplication(config);
        setPublicClient(pc);

        pc.handleRedirectPromise().then((response) =>
        {
            setLoading(false);
            if (response) {
                dispatch(setAccount(response.account));
                setIsAuthenticated(true);
                if(response.accessToken) {
                  dispatch(setLoginToken(response.accessToken));
                  //dispatch(setApiToken(response.accessToken));
                }
            }
        }).catch(error => {
            console.log(error);
            setLoginError(error);
        });

        if (pc.getAccountByUsername()) {
            setUser(pc.getAccountByUsername());
            setIsAuthenticated(true);
        }
        // eslint-disable-next-line
    }, []);

    const login = async (loginRequest, method) => {
        const signInType = (isIE || isEdge) ? "loginRedirect" : method;
        if (signInType === "loginPopup") {
            setPopupOpen(true);

            try {
                await publicClient.loginPopup(loginRequest);

                if (publicClient.getAccountByUsername()) {
                    setUser(publicClient.getAccountByUsername());
                    setIsAuthenticated(true);
                }
            } catch (error) {
                console.log(error);
                setLoginError(error);
            } finally {
                setPopupOpen(false);
            }
        } else if (signInType === "loginRedirect") {
            setLoading(true);

            publicClient.loginRedirect(loginRequest)
        }
    }

    const logout = () => {
        publicClient.logout();
    }

    const getTokenPopup = async (loginRequest) => {
        try {
            const response = await publicClient.acquireTokenSilent(loginRequest);
            setToken(response.accessToken);
        } catch (error) {
            try {
                setPopupOpen(true);

                const response = await publicClient.acquireTokenPopup(loginRequest);

                setToken(response.accessToken);
            }
            catch (error) {
                console.log(error);
                setLoginError(error);
            }
            finally {
                setPopupOpen(false);
            }
        }
    }

    // This function can be removed if you do not need to support IE
    const getTokenRedirect = async (loginRequest) => {
      try {
          const silentResponse = await publicClient.acquireTokenSilent(loginRequest)
          dispatch(setApiToken(silentResponse.accessToken));
      }
      catch(error) {
          console.log(error);
          try{
              setLoading(true);

              publicClient.acquireTokenRedirect(loginRequest);
          }
          catch(error) {
              console.log(error);
              setLoginError(error);
          }
      }

    }

    const getToken = async (loginRequest, method) => {
        const signInType = (isIE || isEdge)? "loginRedirect" : method;
        if(signInType === "loginRedirect") {
            return await getTokenRedirect(loginRequest);
        } else
        {
            return await getTokenPopup(loginRequest);
        }
    }

    return (
        <MsalContext.Provider
            value={{
                isAuthenticated,
                user,
                token,
                loading,
                popupOpen,
                loginError,
                login,
                logout,
                getToken
            }}
        >
            {children}
        </MsalContext.Provider>
    );
};

export default MsalContext;
