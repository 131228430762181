import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loginToken: false,
  apiToken: false,
  account: false,
  firstTrace: true,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoginToken(state, action) {
        const token = action.payload;
        state.loginToken = token;
    },
    setApiToken(state, action) {
        const token = action.payload;
        state.apiToken = token;
    },
    setAccount(state, action) {
        const account = action.payload;
        state.account = account;
    },
    setFirstTrace(state, action) {
        const firstTraceBool = action.payload;
        state.firstTrace = firstTraceBool;
    }
  }
});

export const reducer = slice.reducer;

export const setLoginToken = (token) => (dispatch) => {
  localStorage.setItem('loginToken', token);
  dispatch(slice.actions.setLoginToken(token));
};

export const setApiToken = (token) => (dispatch) => {
  localStorage.setItem('apiToken', token);
  dispatch(slice.actions.setApiToken(token));
};

export const setAccount = (account) => (dispatch) => {
  dispatch(slice.actions.setAccount(account));
};

export const setFirstTrace = (firstTraceBool) => (dispatch) => {
  dispatch(slice.actions.setFirstTrace(firstTraceBool));
};

export default slice;
