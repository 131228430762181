import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import {
  Box,
  LinearProgress,
  makeStyles
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'src/store';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}));

const LoadingScreen = () => {

  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  const user = useSelector((state) => state.user);

  if (user.account) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
