import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Download as DownloadIcon,
  Copy as CopyIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon
} from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { useDispatch, useSelector } from 'src/store';
import { setCurrentTab, showOutputOptions } from 'src/slices/outputOptions';

const tabs = [
  {
    value: 'summary',
    label: 'Summary'
  },
  {
    value: 'detail',
    label: 'Detail'
  },
  {
    value: 'unprocessed',
    label: 'Unprocessed'
  },
  {
    value: 'untraced',
    label: 'Untraced'
  }
];

const sortOptions = [
  {
    value: 'updatedAt|desc',
    label: 'Last update (newest first)'
  },
  {
    value: 'updatedAt|asc',
    label: 'Last update (oldest first)'
  },
  {
    value: 'orders|desc',
    label: 'Total orders (high to low)'
  },
  {
    value: 'orders|asc',
    label: 'Total orders (low to high)'
  }
];

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '12px',
      height: '12px'
    },
    '*::-webkit-scrollbar-track': {
      background: 'rgba(55,71,79,1)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(159,168,218,1)',
      borderRadius: '20px',
      border: '3px solid rgba(55,71,79,1)'
    }
  },
  root: {},
  queryField: {
    width: 500
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
},
  container: {
      maxHeight: 700,
  }
}));

const RenderRow = (props) => {
    let id = props.index;
    return props.keys.map((key, index)=>{
        id = id + key;
        return (
            <TableCell key={id}>
                <Typography
                noWrap
                variant='body2'>
                    {props.data[key]}
                </Typography>
            </TableCell>
        )
    })
}

const Results = ({
  className,
  tables,
  ...rest
}) => {
  const classes = useStyles();
  //const [currentTab, setCurrentTab] = useState('summary');
  const [selectedResults, setSelectedResults] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const [filters, setFilters] = useState({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null
  });
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.outputOptions.currentTab);

  useEffect(() => {
      dispatch(showOutputOptions(true));
  });

  const handleTabsChange = (event, value) => {
    dispatch(setCurrentTab(value));
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllResults = (event) => {
    setSelectedResults(event.target.checked
      ? tables[currentTab].map((customer) => customer.id)
      : []);
  };

  const handleSelectOneCustomer = (event, customerId) => {
    if (!selectedResults.includes(customerId)) {
      setSelectedResults((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedResults((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const getKeys = () => {
      return Object.keys(tables[currentTab][0]);
    }

const applyPagination = (customers, page, limit) => {
  return customers.slice(page * limit, page * limit + limit);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

  const handleSortRequest = key => {
      const isAsc = orderBy === key && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(key);
  }

    const getHeader = () => {
        let keys = getKeys();
        return keys.map((key, index)=>{
            return (
                <TableCell key={key}>
                    <TableSortLabel
                    active = {orderBy === key}
                    direction = {orderBy === key ? order : 'asc'}
                    onClick = {() => {handleSortRequest(key)}}
                    >
                        <Typography
                        noWrap
                        color='primary'
                        variant='h6'>
                            {key}
                        </Typography>
                    </TableSortLabel>
                </TableCell>
            )
        })
    }

    const getRowsData = (paginatedRows) => {
        let keys = getKeys();
        return paginatedRows.map((row, index)=>{
            return <TableRow key={index}><RenderRow key={index} data={row} keys={keys}/></TableRow>
        })
    }

    const createTSV = () => {
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(tables[currentTab][0]);
      let tsv = tables[currentTab].map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join('\t'));
      tsv.unshift(header.join('\t'));
      tsv = tsv.join('\r\n');
      return tsv;
    }

    const createCSV = () => {
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(tables[currentTab][0]);
      let csv = tables[currentTab].map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      csv = csv.join('\r\n');
      return csv;
    }

    const copyTableToClipboard = () => {
        const tsv = createTSV();
        navigator.clipboard.writeText(tsv);
        setOpen(true);
    }

    const exportTableToCSV = () => {
        const csv = createCSV();
        let csvContent = "data:text/csv;charset=utf-8," + csv
        const encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        const filename = 'genealogy-' + currentTab + '-table.csv'
        link.setAttribute("download", filename);
        document.body.appendChild(link); // Required for FF
        link.click();
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
      }

      setOpen(false);
    };

    const toTitleCase = (str) => {
        return str.replace(
            /\w\S*/g,
            (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

  const sortedRows = stableSort(tables[currentTab], getComparator(order, orderBy));
  const paginatedRows = applyPagination(sortedRows, page, limit);
  const enableBulkOperations = selectedResults.length > 0;
  const selectedSomeResults = selectedResults.length > 0 && selectedResults.length < tables[currentTab].length;


  if (tables[currentTab] === undefined) {
      return (
          <Typography
            variant="h4"
            color="textPrimary"
          >
          </Typography>
      )

  }
  else if (tables[currentTab].length == 0) {
      return (
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
            />
          ))}
        </Tabs>
        <Divider />
      </Card>
  );
  }
  else {

      return (
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid container className={classes.root} spacing={0}>
            <Grid item xs={11}>
              <Tabs
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="secondary"
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid container item justify="flex-end" xs={1}>
              <IconButton aria-label="copy" onClick={copyTableToClipboard}>
                <CopyIcon />
              </IconButton>
              <Box mr={1} />
              <IconButton aria-label="export" onClick={exportTableToCSV}>
                <DownloadIcon />
              </IconButton>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={open}
                autoHideDuration={1800}
                onClose={handleClose}
                message="Table copied to clipboard"
              >
              </Snackbar>
            </Grid>
          </Grid>
          <Divider />
          {enableBulkOperations && (
            <div className={classes.bulkOperations}>
              <div className={classes.bulkActions}>
                <Checkbox
                  checked={selectedSomeResults} //fix later
                  indeterminate={selectedSomeResults}
                  onChange={handleSelectAllResults}
                />
                <Button
                  variant="outlined"
                  className={classes.bulkAction}
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  className={classes.bulkAction}
                >
                  Edit
                </Button>
              </div>
            </div>
          )}
            <Box minWidth={700}>
            <TableContainer className={classes.container}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {getHeader()}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getRowsData(paginatedRows)}
                </TableBody>
              </Table>
              </TableContainer>
            </Box>
            <TablePagination
              component="div"
              count={tables[currentTab].length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[25, 50, 100]}
            />
        </Card>
      );
  }

    };


export default Results;
